.cart__header {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 10px 0;
}

.cart__main {
    position: relative;
    width: 100%;
}

.cart-header__security {
    padding: 10px 0;
    float: right;
}

.cart-header__security span {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 11px;
    line-height: 1.2;
    margin-right: 4px;
    text-align: right;
}

.cart-header__security span b {
    font-family: Gotham Medium;
    line-height: 1;
}

.cart-header__security img {
    width: 18px;
    vertical-align: top;
}

.cart__header .header-logo {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    margin: auto;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
}

.cart__header .header-logo img {
    max-height: 50px;
    max-width: 120px;
}

.header-logo.affiliates-logo {
    text-decoration: none;
}

.header-logo.affiliates-logo img {
    max-height: 39px;
}

.header-logo.affiliates-logo .header-affiliates-name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #4d4d4d;
    font-family: "SimplonBP", sans-serif;
    font-size: 15.6px;
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: -10px;
}

@supports (-webkit-overflow-scrolling: touch) {
  .cart-header__security span {
        font-family: inherit;
        font-weight: 300;
    }
}
