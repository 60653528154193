.geo-restriction__header {
    background: #FFF;
    padding: 12px 0;
}

.geo-restriction-logo {
    width: 100%;
    margin-bottom: 10px;
}

.geo-restriction-logo img {
    max-width: 130px;
    max-height: 25px;
    margin: 0 auto;
}

.geo-restriction__header-login-info {
    margin: 0;
}

.geo-restriction__header nav {
    margin: 0 auto;
    width: fit-content;
}

.geo-restriction__header-login-info li {
    margin: 0 5px;
}

.geo-restriction__header-login-info li a {
    font-size: 13px;
    color: #888;
}

.geo-restriction__main {
    position: relative;
    background: #000;
    padding: 30px 0 40px;
}

.geo-restriction__main-bg {
    background-image: url(../img/page-restriction/geo-restriction-bg.jpg);
    background-size: cover;
    background-position: bottom;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.geo-restriction__main h2 {
    color: #FFF;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    position: relative;
    z-index: 2;
}

.geo-restriction__main h2 span {
    font-weight: 300;
    font-size: 19px;
    margin-top: 20px;
    display: block;
}

.geo-restriction__main h3 {
    margin: 40px 0;
    color: #EEE;
    font-weight: 100;
    position: relative;
    z-index: 2;
}

.geo-restriction__main form {
    width: 100%;
    margin: 0;
    position: relative;
    z-index: 2;
}

.geo-restriction__zip-code {
    width: 100%;
    padding: 12px 25px;
    border-color: transparent;
}

.geo-restriction__zip-code {
    width: 80%;
    padding: 12px 25px;
    border-color: transparent;
}

.area-check__cep-button {
    width: 20%;
    float: right;
    height: 48px;
    border-color: transparent;
    background: #939393;
    font-weight: 700;
    color: #FFF;
}

.geo-restriction__footer {
    background: #FFF;
    padding: 10px 0 25px;
}

.footer__copyright {
    color: #888;
}

.ssl {
    background-image: url(../img/icon/common/lets-encrypt-footer.png);
    width: 94px;
    height: 22px;
    display: block;
    background-size: cover;
}

.logo-smplaces {
    background-image: url(../img/icon/common/logo_smplaces.png);
    width: 70px;
    height: 10px;
    display: block;
    background-repeat: no-repeat;
    -webkit-filter: brightness(40%);
    filter: brightness(40%);
}

.geo-restriction__footer-list {
    display: block;
    margin: 0 auto;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin-top: 20px;
}

.restriction__mask {
    display: none;
}

.restriction__message {
    background-color: #fff;
    width: 80%;
    max-width: 680px;
    text-align: center;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 30px 30px;
    -webkit-box-shadow: 0px 0px 87px -5px rgba(102,102,102,1);
    -moz-box-shadow: 0px 0px 87px -5px rgba(102,102,102,1);
    box-shadow: 0px 0px 87px -5px rgba(102,102,102,1);
}

.restriction__close {
    font-size: 11px;
    color: #ffffff;
    background-color: #26535e;
    text-align: center;
    width: 20px;
    height: 20px;
    border: none;
    position: absolute;
    font-weight: 900;
    top: 8px;
    right: 8px;
    padding: 0;
    border-radius: 50%;
}

.restriction__mask--active {
    display: block;
}
