.cart__footer-top {
    padding: 20px 0;
    border-bottom: 1px solid #a7a7a7;
}

.cart-payment {
    width: 58%;
}

.cart-security {
    width: 35%;
    margin-left: 5%;
}

.cart-payment h2,
.cart-security h2 {
    font-family: Gotham Medium;
    color: #333333;
    font-size: 13px;
    text-align: center;
    margin-bottom: 6px;
}

.cart__footer-copyright,
.cart__footer-copyright a {
    font-family: Gotham;
    color: #333333;
    font-size: 13px;
}

.cart__footer-copyright {
    width: 100%;
    margin-top: 20px;
}

@supports (-webkit-overflow-scrolling: touch) {
  .cart__footer-copyright,
  .cart__footer-copyright a {
        font-family: inherit;
        font-weight: 300;
    }
}
