.chk-mail__main {
    border-radius: 5px;
    border: 1px solid #ededed;
    background-color: #ffffff;
    box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
    padding: 20px 25px !important;
}

.checkout-mail__title {
    line-height: 26px;
    font-size: 25px;
    margin-top: 6px;
}

.chk-mail__bg {
    background: #ecf1f8;
}

input[name="authentication-submit"] {
    width: 100%;
}

input[name="social-login-Google"],
input[name="social-login-Facebook"] {
    width: 100%;
    margin-bottom: 8px;
}
