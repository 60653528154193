.sort-by__label {
    display:none !important;
}
.sort-by {
    margin: 0 !important;
    width: 100%;
}
.category__filter-box__switch {
    padding: 5px 0px 0 10px;
    border-right: 1px solid #CCC;
}
.open-order {
    text-align: left;
    color: black;
    border: 1px solid #cccccc;
    background: #FFF;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    width: 40% !important;
    display: inline-block;
    vertical-align: top;
    margin: 2px 10px;
    max-height: 26px;
}
.open-filter {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    float: right;
    margin-right: 15px;
}
.sort-by__select select {
    font-size: 0.7em;
    width: 100%;
    min-width: 100%;
    display: block;
    padding: 4px 9px;
    max-height: 24px;
    vertical-align: top;
}
.change-column {
    float: none;
}
a.change-column-item {
    padding: 0 5px;
    height: 20px;
    margin: 0;
    display: inline-block;
    float: none;
}
.change-column-span {
    width: 10px;
    height: 20px;
    background: #CCC;
    display: inline-block;
}
.category__filter-box__switch,
.change-column {
    display: inline-block;
}
.change-column a:nth-of-type(4),
.change-column a:nth-of-type(5) {
    display:none;
}
span.change-column-span__list {
    width: 20px;
    height: 4px;
    background: #CCC;
    display: block;
    margin-bottom: 3px;
}
span.change-column-span__big {
    width: 20px;
    height: 12px;
    background: #CCC;
    display: block;
    margin-bottom: 2px;
}
span.change-column-span__small {
    width: 20px;
    height: 3px;
    background: #CCC;
    display: block;
}
span.change-column-span__square {
    width: 8px;
    height: 8px;
    float: left;
    display: block;
    background: #CCC;
}
select#sort-by option {
    font-size: 0.8em;
}
a.change-column-item.square-block {
    max-width: 36px;
}
.one-square {
    margin-right: 2px;
    margin-bottom: 2px;
}
.two-square {
    margin-bottom: 2px;
}
.three-square {
    margin-right: 2px;
}
.header-filter {
    padding:1em;
    border-bottom: 1px solid #d7d7d7;
}
.header-filter .filter-close {
    float:right;
}
.filter-close i {
    font-size:1.5em;
}
span.filter-heading {
    font-weight: bold;
    letter-spacing: 0.1em;
}
.category__filter-box {
    border-bottom: 1px solid #DDD;
    padding: 10px 0;
    background: #EEE;
}
