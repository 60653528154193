.cart-top {
    width: 100%;
    margin: 15px 0;
}

.cart-top-heading {
    font-family: Gotham;
    color: #333333;
    font-size: 20px;
    margin-bottom: 0;
}

.cart-top-qty {
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    line-height: 1;
    float: right;
    margin: 5px 0;
}

.cart__btn-plus-minus {
    font-size: 18px;
}

.cart-store-name {
    background: #efefef;
    padding: 8px 0;
}

.cart-store-name a {
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
}

.cart-store-name a b {
    font-family: Gotham Medium;
}

.coupon-link {
    border-top: 1px solid #a7a7a7;
    border-bottom: 1px solid #a7a7a7;
    padding: 12px 15px;
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    line-height: 1;
    width: 100%;
    text-align: right;
}

.coupon-link:before {
    content: "";
    width: 11px;
    height: 11px;
    display: inline-block;
    margin-right: 3px;
    background-image: url(../img/icon/common/cart-tag.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.coupon-area {
    display: none;
}

.input-group {
    width: 100%;
}

.input-group .form-control:focus {
    z-index: 1;
}

.btn-coupon {
    display: table-cell;
    align-items: flex-start;
    text-align: center;
    padding: 8.9px 8px;
    background: #333333;
    color: #FFF !important;
    position: absolute;
    z-index: 1;
    margin-left: -2px;
    border: 2px solid transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 12px;
    line-height: 1;
    right: 0;
}

.min {
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    text-align: center;
    margin: 10px 0;
}

.cart-item-line {
    margin: 0;
    border-top: 1px solid #a7a7a7;
}

.cart__product-item {
    padding: 12px 0;
}

.cart__product-image {
    width: 26%;
    margin-right: 3%;
    max-height: 80px;
}

.cart__product-image img {
    max-width: 100%;
    max-height: 80px;
}

.cart__product-info {
    width: 68.5%;
}

.cart__product-info a {
    font-family: Gotham;
    color: #333333;
    font-size: 13px;
    margin-bottom: 7px;
}

.cart__product-info a.cart__remove-item {
    font-size: 11px;
    text-decoration: underline;
    margin: 4px 0px 0px 4px;
}

.cart__product-info p {
    font-family: Gotham;
    color: #333333;
    font-size: 10px;
    margin-bottom: 0;
    margin-right: 8px;
}

.cart__product-info p span {
    font-family: Gotham Medium;
}

.cart-product-quantity {
    border-color: #a7a7a7;
    width: 60px !important;
    text-align: center;
    font-family: Open Sans;
    color: #333333;
    font-size: 13px;
    line-height: 1;
    float: none !important;
    display: inline-block !important;
}

.cart__product-price {
    margin-top: 10px;
}

.cart__product-price span {
    font-family: Gotham;
    color: #333333;
    font-size: 13px;
    line-height: 1;
}

.cart__product-price span b {
    font-family: Gotham Medium;
}

.cart__clear--all {
    font-family: Gotham;
    color: #848383;
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;
    margin: 25px auto;
}

.cart__freight-form {
    margin-bottom: 12px;
}

.freight-result__CEP {
    font-family: Gotham;
    color: #070707;
    font-size: 13px;
    margin: 0 auto 12px;
}

.freight-result__CEP a {
    color: #009cfe;
    text-decoration: underline;
}

.freight-result__to {
    font-family: Gotham;
    color: #333333;
    font-size: 14px;
    margin-bottom: 8px;
}

.freight-result__to span {
    font-family: Gotham Medium;
}

.cart__footer-freight {
    margin-top: 15px;
}

.shipping-option {
    margin: 5px 0;
}

.no-shipping-options {
    line-height: 1;
    margin-top: 15px;
    background: #d1ecf1;
    padding: 10px;
    color: #0c5460;
    font-size: 12px;
}

.no-shipping-options i {
    font-size: 15px;
    margin-right: 5px;
}

.cart__store-name {
    font-family: Gotham;
    color: #333333;
    font-size: 14px;
    margin-bottom: 6px;
}

.cart__store-name a {
    font-family: Gotham Medium;
    color: #333;
}

.shipping-options {
    position: absolute;
    opacity: 0;
    top: 0;
}

.shipping-options__label {
    margin: 0;
    padding: 4px 0;
    font-family: Gotham;
    font-size: 12.5px;
    color: #333333;
    font-weight: 300;
    position: relative;
    line-height: 1;
    padding-left: 25px;
}

.shipping-option .radio-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 18px;
    width: 18px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50px;
    border: 1px solid #333;
}

.shipping-option.disabled label {
    color: gray;
}

.shipping-option.disabled .radio-btn {
    border-color: gray;
}

.shipping-active .radio-btn:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #333;
    margin: 3px;
    border-radius: 20px;
}

.shipping-options__label.shipping-active {
    font-family: Gotham Medium;
}

.cart-btn {
    background: #343234;
    font-family: Gotham;
    color: #fffefe;
    font-size: 12px;
    padding: 7.5px 10px;
}

.cart__footer-box {
    border-radius: 8px;
    border: 1px solid #a7a7a7;
    margin-bottom: 12px;
    padding: 10px 12px;
}

.cart__resume {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__footer-prices {
    font-family: Gotham;
    color: #333333;
    font-size: 13px;
    margin-bottom: 0;
}

.cart__footer-prices-value {
    float: right;
    font-family: Gotham Medium;
    margin: 0;
}

.cart-btn-finish {
    border-radius: 8px;
    border-color: transparent;
    background-color: #26b70e;
    width: 100%;
    font-family: Gotham Medium;
    color: #ffffff;
    font-size: 15px;
    padding: 10px 0;
    text-transform: uppercase;
}

.cart__continue-shopping, .cart__share {
    border: 1px solid #a7a7a7;
    border-radius: 8px;
    text-align: center;
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    padding: 7px 0;
    margin: 12px 0 40px;
}

.cart__share {
    color: #26b70e;
    border: none;
    display: block;
    margin-top: -19px;
}

.cart__zip-code-input--error {
    font-weight: 500;
    font-size: 12px;
    color: #ff2727;
    width: 100%;
    letter-spacing: 1px;
}

.cart-btn {
    color: #FFF !important;
}

.chk__steps {
    pointer-events: none;
    margin-top: 20px;
}

.chk__steps-description span {
    font-family: Gotham;
    color: #AAA;
    font-size: 13px;
    float: left;
}

.chk__steps-description span.chk__steps-item-active {
    color: #1cade4;
}

.chk__steps-description span.chk__steps-item-checked {
    color: #18619e;
}

.chk__steps-description span.cart-md-step {
    margin: 0 110px;
}

.chk__steps-description span.cart-step {
    margin-right: 100px;
}

.chk__steps-pointline {
    margin-top: 4px;
}

.chk__steps-dot {
    width: 9px;
    height: 9px;
    background: #CACACA;
    border-radius: 35px;
    float: left;
}

.chk__steps-dot.chk__steps-item-active {
    background: #1cade4;
}

.chk__steps-dot.chk__steps-item-checked {
    background: #18619e;
}

.chk__steps-line {
    float: left;
    width: 167px;
    height: 1px;
    background: #CACACA;
    margin-top: 3.5px;
}

.chk__steps-line.chk__steps-item-checked {
    background: #18619e;
}
/* Modal */


#share-cart-modal .modal-content {
    font-family: 'SimplonBP';
    font-weight: 500;
}

#share-cart-modal .modal-content .modal-body{
    font-size: 16px;
    padding: 30px 30px 0;
}
#share-cart-modal .modal-content .modal-footer{
    padding: 16px 30px 14px;
}

#share-cart-modal .modal-content .modal-body .input-group{
    width: 100%;
    padding-top: 28px;
    padding-bottom: 33px;
}

#share-cart-modal h2{
    /* color:#26b70e; */
    color: #009E0B;
    font-family: 'Simplon Oi';
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 30px;
}

#share-cart-modal .contianer__share__link_success{
    color: #26b70e;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    display: none;
    padding-bottom: 8px;
}

#share-cart-modal  .share__link__error{
    color: #ff2727;
    font-size: 14px;
    font-weight: 400;
}

#share-cart-modal .close {
    background-color: #000;
    border: 1px solid #fff;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    opacity: 1;
    color: #fff;
    z-index: 1;
    position: absolute;
    right: -10px;
    top: -10px;
    font-weight: 100;
    font-size: 20px;
    padding-bottom: 5px;
}

#share-cart-modal .modal-footer .btn.btn-primary {
    background-color: #009E0B;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    height: 46px;
}
#share-cart-modal .modal-footer .form-group {
    min-height: 0;
}

#share-cart-modal .modal-footer .form-control[disabled].success {
    color: #009E0B;
    -webkit-text-fill-color: #009E0B;
}

#share-cart-modal .switch {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 50px;
    margin-bottom: 0;
    margin-left: 10px;
}
#share-cart-modal .container-has-fiber{
    display: flex;
    flex-flow: row wrap;
    align-content: center;
}

#share-cart-modal .switch input {
    display: block;
    position: absolute;
    z-index: 1;
    opacity: 0;
    width: 50px;
    cursor: pointer;
}
#share-cart-modal #share-link-input-fiber{
    font-family: 'Simplon Oi';
    width: 100%;
    border-radius: 6px;
    height: 46px;
    font-size: 13px;
}
#share-cart-modal #share-link-input-hidden{
    display: none;
}
#share-cart-modal .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

#share-cart-modal .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 16px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 16px;
}

#share-cart-modal .switch input:checked + .slider {
    background-color: #009E0B;
    text-transform: uppercase;
}

#share-cart-modal .switch input:checked + .slider:before {
    transform: translateX(26px);
}

#share-cart-modal .slider.round {
    border-radius: 34px;
}

#share-cart-modal .slider.round:before {
    border-radius: 50%;
}

#share-cart-modal #qr-code-link-cart{
    background-color: #009E0B;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    height: 46px;
    border-radius: 0 6px 6px 0;
}

#share-cart-modal #qr-code-link-cart.disable{
    background-color: #cccccc;
}

#share-cart-modal .container-qrcode{
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
}

#share-cart-modal .container-qrcode img{
    width: 200px;
}

#share-cart-modal .container-fiber{
    display: none;
}

#share-cart-modal .modal-footer .form-control[disabled] {
    background-color: transparent;
    border-radius: 6px 0 0 6px;
    height: 46px;
    font-size: 15px;
    cursor: default;
}

#share-cart-modal .modal-footer #share-link-cart.disable{
    background-color: #cccccc;
}

@media (min-width: 375px) {

    #share-cart-modal .modal-content .modal-body{
        font-size: 18px;
    }

    #share-cart-modal h2{
        font-size: 22px;
    }

    #share-cart-modal .modal-footer .form-control[disabled] {
        font-size: 15px;
    }
    
    #share-cart-modal #share-link-input-fiber {
        font-size: 15px;
    }

}
@media (min-width: 768px) {
    #share-cart-modal .modal-dialog {
        width: 600px;
    }

    #share-cart-modal .modal-footer .form-group {
        width: 100%;
    }

    #share-cart-modal .modal-footer #share-link-cart{
        width: 71px
    }

}

/* Modal */
@media (max-width: 650px) {
    .chk__steps-description span.cart-md-step {
        margin: 0 60px;
    }
    .chk__steps-description span.cart-step {
        margin-right: 60px;
    }
    .chk__steps-line {
        width: 121px;
    }
}

@media (max-width: 480px) {
    .chk__steps-description span.cart-md-step {
        margin: 0 20px;
    }
    .chk__steps-description span.cart-step {
        margin-right: 20px;
    }
    .chk__steps-line {
        width: 80px;
    }
}

@media (max-width: 350px) {
    .chk__steps-description span.cart-md-step {
        margin: 0 10px;
    }
    .chk__steps-description span.cart-step {
        margin-right: 10px;
    }
    .chk__steps-line {
        width: 68px;
    }
}
/* Warnings */
#box_warning_change_cart_js .container-alert-warning{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    flex-flow: wrap column;
}


/* crossbrowser */

@supports (-webkit-overflow-scrolling: touch) {
    .cart-top-heading,
    .cart-top-qty,
    .cart-store-name a,
    .coupon-link a,
    .min,
    .cart__product-info a,
    .cart__product-info p,
    .cart-product-quantity,
    .cart__product-price span,
    .cart__clear--all,
    .freight-result__CEP,
    .freight-result__to,
    .cart__store-name,
    .shipping-options__label,
    .cart-btn,
    .cart__footer-prices,
    .cart__continue-shopping {
        font-family: inherit;
        font-weight: 300;
    }
    .cart-btn-finish,
    .shipping-options__label.shipping-active,
    .cart__footer-prices-value {
        font-family: inherit;
        font-weight: 600;
    }
}
